import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import Headline from "../components/Headline";

export const query = graphql`
{
  allSanityImpressum {
  edges{
    node{
        _rawDaten
        _rawImpressum
    }
    
  }
  }
  allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
  }
  }
  }
 `

const Impressum = ({data}) => {
  return (
    <Layout agbs={data.allSanityAgbs.edges[0].node.agbfile.asset.url}>
        <SEO title={"Impressum | Matthey & Melchior"}/>
        <div className="basepage">
            <div className={"headline__container"} >
                <h1 className={"center minion"}>Impressum</h1>
                <div className="separator title"></div>
            </div>
            <div className="impressumtext">
                <MyPortableText value={data.allSanityImpressum.edges[0].node._rawDaten}></MyPortableText>
            </div>
            <div className="text">
                <MyPortableText value={data.allSanityImpressum.edges[0].node._rawImpressum}></MyPortableText>
            </div>
        </div>
    </Layout>
  )
}

export default Impressum
